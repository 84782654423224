<template>
  <div class="wrapper">
    <div class="total-box-1">
      <admin-title title="实时概况" bottom="0px"></admin-title>
      <div class="content">
        <div class="item animate__animated animate__backInLeft animate__faster">
          <div class="left">
            <i class="el-icon-coin"></i>
          </div>
          <div class="title">
            <span class="num"> {{ (overview.orderTotalPrice && overview.orderTotalPrice.tday) || 0 }}</span>
            <span class="label">积分</span>
            <div class="sub-title">昨日+{{ (overview.orderTotalPrice && overview.orderTotalPrice.ytd) || 0 }}</div>
          </div>
        </div>
        <div class="item animate__animated animate__backInLeft animate__faster">
          <div class="left">
            <i class="el-icon-tickets"></i>
          </div>
          <div class="title">
            <span class="num"> {{ (overview.orderTotal && overview.orderTotal.tday) || 0 }}</span>
            <span class="label">积分订单数</span>
            <div class="sub-title">昨日+{{ (overview.orderTotal && overview.orderTotal.ytd) || 0 }}</div>
          </div>
        </div>
        <div class="item animate__animated animate__backInRight animate__faster">
          <div class="left">
            <i class="el-icon-user"></i>
          </div>
          <div class="title">
            <span class="num"> {{ (overview.newUserTotal && overview.newUserTotal.tday) || 0 }}</span>
            <span class="label">新增会员数</span>
            <div class="sub-title">昨日+{{ (overview.newUserTotal && overview.newUserTotal.ytd) || 0 }}</div>
          </div>
        </div>
        <div class="item animate__animated animate__backInRight animate__faster">
          <div class="left">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="title">
            <span class="num"> {{ (overview.consumeUserTotal && overview.consumeUserTotal.tday) || 0 }}</span>
            <span class="label">兑换会员数</span>
            <div class="sub-title">昨日+{{ (overview.consumeUserTotal && overview.consumeUserTotal.ytd) || 0 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="total-box">
      <div class="item animate__animated animate__flipInX">
        <div class="left">
          <i class="el-icon-s-cooperation"></i>
        </div>
        <div class="title">
          <span class="num"> {{ stat.goodsTotal || 0 }}</span>
          <span class="label">商品总数量</span>
        </div>
      </div>
      <div class="item animate__animated animate__flipInX">
        <div class="left">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="title">
          <span class="num"> {{ stat.userTotal || 0 }}</span>
          <span class="label">会员总人数</span>
        </div>
      </div>
      <div class="item animate__animated animate__flipInX">
        <div class="left">
          <i class="el-icon-s-claim"></i>
        </div>
        <div class="title">
          <span class="num"> {{ stat.orderTotal || 0 }}</span>
          <span class="label">兑换订单总量</span>
        </div>
      </div>
      <div class="item animate__animated animate__flipInX">
        <div class="left">
          <i class="el-icon-s-custom"></i>
        </div>
        <div class="title">
          <span class="num"> {{ stat.consumeUserTotal || 0 }}</span>
          <span class="label">消费总人数</span>
        </div>
      </div>
    </div>
    <!--  -->
<!--    <div class="count-box">-->
<!--      <div class="item animate__animated animate__flipInX animate__faster">-->
<!--        <div class="label">待发货订单（笔）</div>-->
<!--        <div class="value">{{ pending.deliverOrderTotal || 0 }}</div>-->
<!--      </div>-->
<!--      <div class="item animate__animated animate__flipInX animate__faster">-->
<!--        <div class="label">售后单（笔）</div>-->
<!--        <div class="value">{{ pending.refundTotal || 0 }}</div>-->
<!--      </div>-->
<!--      <div class="item animate__animated animate__flipInX animate__faster">-->
<!--        <div class="label">待付款订单（笔）</div>-->
<!--        <div class="value">{{ pending.paidOrderTotal || 0 }}</div>-->
<!--      </div>-->
<!--      <div class="item animate__animated animate__flipInX animate__faster">-->
<!--        <div class="label">已售罄商品</div>-->
<!--        <div class="value">{{ pending.soldoutGoodsTotal || 0 }}</div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- 常用功能 -->
    <div class="utils-box" style="margin-top: 20px;">
      <admin-title title="常用功能" bottom="10px"></admin-title>
      <div class="content">
        <div class="item animate__animated animate__bounceIn animate__faster" @click="$router.push({ name: 'GoodsAdd' })">
          <div class="left orange">
            <i class="el-icon-s-goods"></i>
          </div>
          <div class="right">发布商品</div>
        </div>
        <div class="item animate__animated animate__bounceIn animate__faster" @click="$router.push({ name: 'UserList' })">
          <div class="left red">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="right">会员管理</div>
        </div>
        <div class="item animate__animated animate__bounceIn animate__faster" @click="$router.push({ name: 'OrderJiFenList' })">
          <div class="left green">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="right">订单管理</div>
        </div>
<!--        <div class="item animate__animated animate__bounceIn animate__faster" @click="$router.push({ name: 'AfterSaleList' })">-->
<!--          <div class="left blue">-->
<!--            <i class="el-icon-user-solid"></i>-->
<!--          </div>-->
<!--          <div class="right">售后管理</div>-->
<!--        </div>-->
<!--        <div class="item animate__animated animate__bounceIn animate__faster">-->
<!--          <div class="left blue">-->
<!--            <i class="el-icon-s-data"></i>-->
<!--          </div>-->
<!--          <div class="right">数据统计</div>-->
<!--        </div>-->
        <div class="item animate__animated animate__bounceIn animate__faster" @click="$router.push({ name: 'OrderCoinList' })">
          <div class="left red">
            <i class="el-icon-s-ticket"></i>
          </div>
          <div class="right">游戏币兑换</div>
        </div>
      </div>
    </div>

    <!-- <div class="chart-box">
      <div v-if="show" class="item item animate__animated animate__zoomIn animate__faster">
        <LineChart v-if="show" title="近7天订单新增" :list_x="order_x" :list_y="order_y" />
      </div>
      <div v-if="show" class="item item animate__animated animate__zoomIn animate__faster">
        <LineChart v-if="show" title="近7天用户新增" :list_x="user_x" :list_y="user_y" />
      </div>
    </div> -->

    <!-- <div style="margin-top: 20px" class="chart-box">
      <LineChart v-if="show" title="近7天订单新增" :list_x="tradeTrend.date" :list_y="tradeTrend.orderTotal" />
    </div> -->

    <div style="margin-top: 20px" class="chart-box">
      <admin-title title="近七日交易走势" bottom="0px"></admin-title>
      <LineChartMult
        v-if="show"
        title=""
        :list_x="tradeTrend.date"
        :list_y1="tradeTrend.orderTotal"
        :legend="['成交量']"
      ></LineChartMult>
    </div>
  </div>
</template>

<script>
import LineChart from './charts/line-chart.vue'
import LineChartMult from './charts/line-chart-mult.vue'
import { getIndexStatAPI } from './api'

export default {
  name: 'Home',
  components: { LineChart, LineChartMult },
  data() {
    return {
      show: false,
      stat: {},
      overview: {},
      pending: {},
      tradeTrend: {}
    }
  },
  computed: {},

  mounted() {
    this.getIndexStat()
  },

  methods: {
    async getIndexStat() {
      const res = await getIndexStatAPI()
      this.show = true
      this.overview = res.overview || {}
      this.stat = res.statistics || {}
      this.pending = res.pending || {}
      this.tradeTrend = res.tradeTrend || {}
    }
    // 发布商品
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  height: 120vh;
  // background-color: #f0f2f5;
  .total-box-1 {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;

      justify-content: center;

      .item {
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 30px 0;
        // box-shadow: 0 0px 8px 0 rgba(114, 114, 114, 0.1);
        border-radius: 4px;
        transition: all 0.2s linear;

        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          width: 58px;
          height: 58px;
          background-color: #eff6ff;
          border-radius: 50%;
          i {
            font-size: 30px;
            color: #1890ff;
          }
        }
        .title {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .label {
            font-size: 14px;
          }
          .num {
            font-weight: 700;
            font-size: 18px;
          }
          .sub-title {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(90%, -80%);
            white-space: nowrap;
            background-color: #0571f2;
            line-height: 20px;
            padding: 0 10px;
            border-radius: 20px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .total-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    // padding: 20px 0;
    justify-content: center;
    .item {
      // cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: 30px 0;
      // box-shadow: 0 0px 8px 0 rgba(114, 114, 114, 0.1);
      border-radius: 2px;
      transition: all 0.2s linear;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        width: 58px;
        height: 58px;
        background-color: #eff6ff;
        border-radius: 50%;
        i {
          font-size: 30px;
          color: #1890ff;
        }
      }
      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 14px;
        }
        .num {
          font-weight: 700;
          font-size: 18px;
        }
        .sub-title {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(90%, -80%);
          white-space: nowrap;
          background-color: #0571f2;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 20px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .count-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    gap: 20px;
    background: #fff;
    margin: 20px 0;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      &:hover {
        cursor: pointer;
        .label {
          color: #0571f2;
        }
      }
      .label {
        font-weight: 700;
      }
      .value {
        font-size: 16px;
        color: #f56c6c;
        font-weight: 700;
      }
    }
  }
  .utils-box {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;
      justify-content: center;
      .item {
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 27%;

        &:hover {
          cursor: pointer;
          .right {
            color: #0571f2;
          }
        }
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          border-radius: 3px;
          margin-right: 10px;

          i {
            font-size: 15px;
            color: #fff;
          }
        }
        .blue {
          background-color: #0571f2;
        }
        .green {
          background-color: #00bf57;
        }
        .red {
          background-color: #f15858;
        }
        .orange {
          background: #e6a23c;
        }
      }
    }
  }
  .chart-box {
    padding: 20px;
    background: #fff;
  }
}
</style>
